<template>
  <div>

    <ERTPLoadingSpinner v-model="isLoading">
      <div class="row px-3">
        <ERTPPricePlan
          class="me-1"
          :class="hasStandardPlan ? 'border-primary' : 'border-info'"
          name="Standard Plan"
          :trips=defaultMaxSignedInRoadtrips
          :stops=defaultMaxSignedInStops
          maps="External (w/Ads)"
          sharing
          ads
          sponsored
          alerts="Limited"
          price="Free"
        >
          <div v-if="hasStandardPlan" class="h-100 d-flex flex-column justify-content-between text-center">
            <p class="mb-3 text-muted"><small>Your current plan.</small></p>
          </div>
          <div v-else-if="!hasSignedUp" class="h-100 d-flex flex-column justify-content-between text-center">
            <p class="text-muted"><small>Once you sign up, your road trips, stops, and other information will be saved so that you can access them whenever you sign in again.</small></p>
            <div>
              <button
                type="button" 
                class="btn btn-primary mt-3 mb-2"
                @click="signUp">Sign Up
              </button>
              <p class="mb-3 text-muted"><small>Create a standard account.</small></p>
            </div>
          </div>
        </ERTPPricePlan>
        <ERTPPricePlan
          class="me-1"
          :class="hasSubscription ? 'border-primary' : 'border-info'"
          name="Premium Plan"
          :trips=defaultMaxSubscribedRoadtrips
          :stops=defaultMaxSubscribedStops
          maps="Dynamic"
          sponsored="Opt-in"
          ads="Ad-free"
          sharing
          alerts="Custom"
          price="$34/yr"
        >
          <div v-if="canSubscribe" class="h-100 d-flex flex-column justify-content-between text-center">
            <p class="mb-3 text-muted"><small>You'll be billed for your first year immediately after submitting your payment information. You can cancel any time.</small></p>
            <div>
              <button
                type="button" 
                class="btn btn-primary mt-3 mb-2"
                @click="subscribe">Subscribe
              </button>
              <p class="mb-3 text-muted"><small>Subscribe to a Premium Plan.</small></p>
            </div>
          </div>
          <div v-else-if="hasSubscription" class="h-100 d-flex flex-column justify-content-between text-center">
            <p class="mb-3 text-muted"><small>You're currently subscribed to a Premium Plan. Create and share tons of epic road trips with customized itineraries.</small></p>
            <div>
              <button
                type="button" 
                class="btn btn-secondary mt-3 mb-2"
                @click="cancel">Cancel
              </button>
              <p class="mb-3 mt-auto align-self-end align-content-end text-muted"><small>Your current plan.</small></p>
            </div>
          </div>
          <div v-else class="h-100 d-flex flex-column justify-content-between text-center">
            <p class="text-muted"><small>Sign up for a Standard Plan, or sign in to your existing Standard Plan to upgrade to Premium.</small></p>
          </div>
        </ERTPPricePlan>
        <ERTPPricePlan
          class="border-info"
          name="Affiliate Plan"
          trips="Custom"
          stops="Custom"
          maps="Custom"
          sponsored="Custom"
          ads="Custom"
          sharing
          alerts="Custom"
          price="Depends on affiliate"
        >
          <div v-if="hasVerfiedEmail" class="mt-auto text-center">
            <p class="mb-3 text-muted"><small>Need even more highly personalized travel planning services, from a real human trip planner? Get an introduction to one of our curated affiliates.</small></p>
            <button
              type="button" 
              class="btn btn-primary mt-3 mb-2"
              :class="hasSignedUp ? 'btn-primary' : 'btn-secondary'"
              @click="referral">Request a Referral
            </button>
            <p class="mb-3 text-muted"><small>Custom personalized road trips.</small></p>
          </div>
          <div v-else-if="hasUnverifiedEmail" class="h-100 d-flex flex-column justify-content-between text-center">
            <p class="text-muted"><small>Need even more highly personalized travel planning services, from a real human trip planner? Verify your email address to request a referral to one of our curated affiliates.</small></p>
          </div>
          <div v-else class="h-100 d-flex flex-column justify-content-between text-center">
            <p class="text-muted"><small>Need even more highly personalized travel planning services, from a real human trip planner? Sign up for a Standard Plan, or sign in to your existing Standard Plan to request a referral to one of our curated affiliates.</small></p>
          </div>

        </ERTPPricePlan>
      </div>
      <div v-if="hasSubscription" class="border border-rounded rounded-3 pt-3 mx-1 mb-3 text-center">
        <p class="px-3 mb-3 text-muted"><small>You can always <a target="_blank" href="https://hillwoodpark.com/contact">contact us</a> to cancel or request a no-hassle refund at any time.</small></p>
      </div>

    </ERTPLoadingSpinner> 

    <ERTPCancelSubscriptionModal
      v-if="showCancelSubscriptionModal" 
      @onSuccess="onSuccessCancelSubscription"
      @onCancel="onCancelCancelSubscription"
      @onError="onErrorCancelSubscription"
    />

    <ERTPAffiliateReferralModal
      v-if="showAffiliateReferralModal" 
      @onSuccess="onSuccessReferral"
      @onCancel="onCancelReferral"
      @onError="onErrorReferral"
    />

  </div>

</template>

<script setup lang="ts">
import { computed, ref } from 'vue';
import ERTPPricePlan from "../components/ERTPPricePlan.vue";
import { 
  DEFAULT_MAX_SIGNEDIN_ROADTRIPS,
  DEFAULT_MAX_SUBSCRIBED_ROADTRIPS,
  DEFAULT_MAX_SIGNEDIN_STOPSPERTRIP,
  DEFAULT_MAX_SUBSCRIBED_STOPSPERTRIP
} from '../models/profile';
import ERTPCancelSubscriptionModal from './modals/ERTPCancelSubscriptionModal.vue';
import ERTPAffiliateReferralModal from './modals/ERTPAffiliateReferralModal.vue';
import ERTPLoadingSpinner from './ERTPLoadingSpinner.vue';
import { useResultMessage } from "../composables/resultMessage";
import { useProfile } from '../composables/profile';
import { useUserState } from '../composables/userState';
import { useServices } from '../composables/services';
import { useAuthModals } from '../composables/authModals';

const { userState } = useUserState(); 
const { resultMessage } = useResultMessage();
const { profilesService } = useServices(); 
const { profile } = useProfile();
const { showSignUpModal } = useAuthModals();

const showCancelSubscriptionModal = ref(false);
const showAffiliateReferralModal = ref(false);

const hasSubscription = profilesService.hasSubscription;
const hasSignedUp = computed(() => {
  return userState.value.isSignedIn && !userState.value.isAnonymous; 
})

const hasStandardPlan = computed(() => {
  return hasSignedUp.value && !hasSubscription.value
});

const canSubscribe = computed(() => {
  return hasSignedUp.value && !hasSubscription.value
})

const hasVerfiedEmail = profilesService.emailVerified;

const hasUnverifiedEmail = computed(() => {
  return profile.value?.email && !profilesService.emailVerified.value
});

const isLoading = profilesService.isLoading;

const defaultMaxSignedInRoadtrips = String(DEFAULT_MAX_SIGNEDIN_ROADTRIPS);
const defaultMaxSignedInStops = String(DEFAULT_MAX_SIGNEDIN_STOPSPERTRIP);
const defaultMaxSubscribedRoadtrips = String(DEFAULT_MAX_SUBSCRIBED_ROADTRIPS);
const defaultMaxSubscribedStops = String(DEFAULT_MAX_SUBSCRIBED_STOPSPERTRIP);

async function subscribe() {
  try {
    resultMessage.value = undefined;
    const stripeSessionUrl = await profilesService.getStripeSessionUrl();
    window.open(stripeSessionUrl);
  } catch (error) {
    resultMessage.value = (error as Error).message || "Something went wrong. Please try again later.";
  }
}

async function signUp() {
  resultMessage.value = undefined;
  showSignUpModal.value = true;
}

async function cancel() {
  resultMessage.value = undefined;
  showCancelSubscriptionModal.value = true;
}

function onCancelCancelSubscription() {
  showCancelSubscriptionModal.value = false;
}

function onSuccessCancelSubscription() {
  resultMessage.value = "Subscription canceled.";
  showCancelSubscriptionModal.value = false;
}

function onErrorCancelSubscription(message: string) {
  resultMessage.value = message || "Something went wrong. Please try again later.";
  showCancelSubscriptionModal.value = false;
}

async function referral() {
  resultMessage.value = undefined;
  showAffiliateReferralModal.value = true;
}

function onCancelReferral() {
  showAffiliateReferralModal.value = false;
}

function onSuccessReferral() {
  resultMessage.value = "Referral email sent!";
  showAffiliateReferralModal.value = false;
}

function onErrorReferral(message: string) {
  resultMessage.value = message || "Something went wrong. Please try again later.";
  showAffiliateReferralModal.value = false;
}

</script>
