<template>
  <div ref="cancelSubscriptionModalRef" class="modal" tabindex="-1" >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Cancel Subscription</h5>
          <button type="button" class="btn-close" @click="onCancel" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <p>Are you sure you want to cancel your subscription?</p>
          <p>After you cancel, you'll continue to have access to your road trips, and premium features will continue to be available to you until your current subscription expires.</p>
          <p>After your subscription then expires, you'll continue to have access to your road trips, but you'll need to renew your subscription to modify them or use any other premium features.</p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" @click="onCancel">Keep It</button>
          <button type="button" class="btn btn-danger" @click="onCancelSubscription">Cancel It</button>
        </div>
       </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue';
import { useProfile } from '../../composables/profile';
import { useServices } from '../../composables/services';
import { useBootstrap } from '../../composables/bootstrap';

const { 
  showModal,
  hideModal,  
} = useBootstrap();

const emit = defineEmits(['onSuccess', 'onCancel', 'onError']);

const {
  profilesService
} = useServices();

const {
  profile
} = useProfile();

const cancelSubscriptionModalRef = ref<HTMLDivElement>();

async function onCancelSubscription() {
  if (!profile.value?.profileId) { console.log("No profile; can't cancel."); return; }
  try {
    await profilesService.cancelSubscription(profile.value?.profileId);
    await profilesService.populateProfile();
    
    emit("onSuccess");
    
  } catch (error) {

    emit("onError", (error as Error).message || "Something went wrong. Please try again later.");

  } finally {

    hideModal(cancelSubscriptionModalRef, onHidden);

  };
}

function onHidden() {
  emit('onCancel')
}

watch(
  cancelSubscriptionModalRef,
  () => {
    if (!cancelSubscriptionModalRef.value) return; // probably not mounted
    showModal(cancelSubscriptionModalRef, onHidden);
  }, 
  {immediate: true}
);

function onCancel() {
  hideModal(cancelSubscriptionModalRef);
}
</script>
