<template>
  <div class="d-flex flex-row w-100 justify-content-between align-items-between">
    <div v-uid ref="featureName" class="ertp-price-plan-feature-value">
      {{ props.featureName }}
    </div>
    <div v-if="isStringValue" class="ertp-price-plan-feature-value text-center">
      {{ featureValue }}
    </div>
    <ERTPBooleanIcon v-else 
      class="ertp-price-plan-feature-value" 
      :icon-value="!!featureValue"
      :aria-labelledby="featureName?.id"
      />
  </div>    
</template>

<script setup lang="ts">
import { computed, ref } from 'vue';
import ERTPBooleanIcon from './ERTPBooleanIcon.vue';

const featureName = ref();

const props = defineProps<{
  featureName: string,
  featureValue?: boolean | string,
}>();

const isStringValue = computed(() => {
  return typeof props.featureValue == "string";
});
</script>