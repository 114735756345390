<template>
  <div ref="affiliateReferralModalRef" class="modal" tabindex="-1" >
    <div class="modal-dialog">
      <div v-if="isLoading" class="model-content">
        <div class="modal-body text-center">
          <div class="spinner-border" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div> 
      </div>
      <div v-else-if="affiliate" class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ affiliate.name }} Referral</h5>
          <button type="button" class="btn-close" @click="onCancel" aria-label="Close"></button>
        </div>
        <div v-if="isSending" class="modal-body text-center">
          <h6>Emailing {{ affiliate.name }}...</h6>
          <div class="spinner-border" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
        <div v-else class="modal-body" role="document">
          <p>{{ affiliate.referralText }}</p>
          <div class="border border-rounded rounded-3 px-3 py-2 mb-3">
            <p class="text-muted">We'll share at least your name and email address with them, so that they can contact you. If you choose to have us do so, we'll also share these other details that may be helpful in planning your custom trip.</p>
            <div class="form-check text-muted">
              <input class="form-check-input" type="checkbox" value="" v-uid ref="shareRoadtripDetails" v-model="includeRoadtripDetails">
              <label class="form-check-label text-muted" :for="shareRoadtripDetails?.id">
                Share my road trip details
              </label>
            </div>
            <div class="form-check text-muted">
              <input class="form-check-input" type="checkbox" value="" v-uid ref="shareInterests" v-model="includeInterests">
              <label class="form-check-label text-muted" :for="shareInterests?.id">
                Share my profile interests
              </label>
            </div>
          </div>
          <p>Would you like an email introduction to <a :href="affiliate.referralUrl" target="_blank">{{ affiliate.name }}?</a></p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" :disabled="isLoading" @click="onCancel">No Thanks</button>
          <button type="button" class="btn btn-primary" :disabled="isLoading" @click="onSendIntroduction">Let's GO!</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from 'vue';
import { Affiliate } from '../../models/affiliate';
import { useServices } from '../../composables/services';
import { useBootstrap } from '../../composables/bootstrap';

const { 
  showModal,
  hideModal,  
} = useBootstrap();

const emit = defineEmits(['onSuccess', 'onCancel', 'onError']);
const includeRoadtripDetails = ref(false);
const includeInterests = ref(false);

const shareRoadtripDetails = ref();
const shareInterests = ref();

const affiliateReferralModalRef = ref<HTMLDivElement>();

const { 
  affiliatesService,
  referralsService
} = useServices();

const isLoading = computed(() => {
  return referralsService.isLoading.value || affiliatesService.isLoading.value
});

const affiliate = ref<Affiliate>();

const isSending = ref(false);
async function onSendIntroduction() {
  if (!affiliate.value) throw new Error('No affiliate selected');
  try {
    isSending.value = true;
    await referralsService.requestReferral(affiliate.value.affiliateId, { 
      includeRoadtripDetails: includeRoadtripDetails.value, 
      includeInterests: includeInterests.value 
    });
    isSending.value = false;
    onSuccess();
  } catch (error) {
    isSending.value = false;
    onError(error);
  }
};

async function populateNextAffiliate() {
  affiliate.value = await referralsService.getNextReferralAffiliate();
}

onMounted(() => {
  populateNextAffiliate();
  showModal(affiliateReferralModalRef);
});

function onCancel() {
  hideModal(affiliateReferralModalRef);
  emit('onCancel')
}

function onSuccess() {
  hideModal(affiliateReferralModalRef);
  emit('onSuccess')
}

function onError(error: any) {
  hideModal(affiliateReferralModalRef);
  emit('onError', error)
}
</script>

